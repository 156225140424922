'use client'

import TemplateCard from './card'
import Button from '@/components/button'
import { Template } from '@/types'
import MagicWaterfall from '../waterfall/magic'
import IconRefresh from '@haiper/icons-svg/icons/outline/refresh-cw.svg'
import IconMenuCircleHor from '@haiper/icons-svg/icons/outline/menu-circle-hor.svg'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { cls, whisper } from '@/utils'
import useGridColumns from '@/hooks/useGridColumns'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { shuffle } from 'lodash-es'

export interface TemplateSubGroupProps {
  className?: string
  name: string
  label: string
  templates: Template[]
  hasMore?: boolean
  onLoadMore?: () => void
  containerRef?: React.RefObject<HTMLDivElement>
}

export default function TemplateSubGroup({
  className,
  name,
  label,
  templates,
  hasMore,
  onLoadMore,
  containerRef,
}: TemplateSubGroupProps) {
  const { isBelowMd } = useBreakpoint('md')

  const gridStyle = cls('w-full grid grid-cols-2 md:grid-cols-4 gap-3')
  const columnCount = useGridColumns(gridStyle)
  const gapSize = 3 * 4
  const paddingSize = isBelowMd ? 0 : 4 * 4
  // const templateCardWidth = ((ref.current?.clientWidth ?? 0) - paddingSize * 2 - (columnCount - 1) * gapSize) / columnCount
  const templateCardWidth =
    ((containerRef?.current?.clientWidth ?? 0) - paddingSize * 2 - (columnCount - 1) * gapSize) / columnCount

  const maxVisibileItems = isBelowMd ? 8 : 8

  const templateCardStyle: CSSProperties = useMemo(() => {
    return {
      width: `${templateCardWidth}px`,
    }
  }, [templateCardWidth])

  const [refreshKey, setRefreshKey] = useState(nanoid())

  const filteredTemplates = useMemo(() => {
    if (!refreshKey) {
      return []
    }
    return shuffle(templates)
      .slice(0, maxVisibileItems)
      .sort((a, b) => a.rank - b.rank)
  }, [templates, maxVisibileItems, refreshKey])

  const refresh = useCallback(() => {
    setRefreshKey(nanoid())
  }, [])

  if (!templates.length) {
    return null
  }

  return (
    <div key={name} className={cls('flex flex-col w-full gap-2 max-w-full', className)}>
      {name ? (
        <div className='w-full flex items-center justify-between' aria-label='title'>
          <span className='text-heading-md tracking-32 font-bold'>{label}</span>
          <div className='flex items-center gap-4'>
            <Button variant='link' className='px-0 py-1' onClick={refresh}>
              <div className='flex items-center gap-1'>
                <IconRefresh className='size-5 text-icon' />
                <span className='flex-1 text-text'>Change</span>
              </div>
            </Button>
            {hasMore && (
              <Button variant='link' className='px-0 py-1' onClick={onLoadMore}>
                <div className='flex items-center gap-1'>
                  <IconMenuCircleHor className='size-5 text-icon' />
                  <span className='flex-1 text-text'>More</span>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : null}
      <MagicWaterfall aria-label='gallery' gutter={12} columns={isBelowMd ? 2 : 4}>
        {filteredTemplates.map((template) => {
          return (
            <TemplateCard
              key={template?.template_id}
              data={template}
              style={templateCardStyle}
              className='absolute transition-none'
            />
          )
        })}
      </MagicWaterfall>
    </div>
  )
}
