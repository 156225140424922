'use client'

import SearchInput from '@/components/search-input'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconPolygon from '@/public/assets/polygon.svg'
import Button from '@/components/button'
import { cls, snakeToSpace, whisper } from '@/utils'
import { useCachedAllTemplates } from '@/hooks/useTemplates'
import useSearchTemplates from '@/hooks/useSearchTemplates'
import { SearchTemplateResultItem, Template, TemplateCategory } from '@/types'
import { useRouter } from 'next/navigation'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import TemplateIcon from './icon'
import { useCachedHotTemplates } from '@/hooks/useHotTemplates'
import Loading from '../loading'
import { useWindowSize } from 'usehooks-ts'
import IconEmpty from '@/public/assets/empty.svg'
import IconUpload from '@haiper/icons-svg/icons/outline/upload.svg'
import TemplateSubGroup, { TemplateSubGroupProps } from './sub-group'

export interface TemplatesExploreProps {
  className?: string
}

const hotTemplatesQueryParams = {
  page: 1,
  limit: 9999,
}

export default function TemplatesExplore({ className }: TemplatesExploreProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const { data: templateCategories } = useCachedTemplateCategories()
  const { data: hotTemplates } = useCachedHotTemplates(hotTemplatesQueryParams)
  const {
    data: allTemplates,
    isValidating: allTemplatesLoading,
    refresh: refreshAllTemplates,
  } = useCachedAllTemplates()

  // const [activeKey, setActiveKey] = useState(templateCategories?.[0]?.category)
  const [activeCategoryKey, setActiveCategoryKey] = useState('hot')
  const categoryContainerRef = useRef<HTMLDivElement | null>(null)

  const displayTemplatesCategories: TemplateCategory[] = useMemo(() => {
    const hasHotTemplate = templateCategories?.some(({ category }: TemplateCategory) => category === 'hot')
    return [
      ...(hotTemplates?.records?.length && !hasHotTemplate
        ? [{ category: 'hot', label: 'Hot', icon: 'https://scontent.haiper.ai/webapp/images/templates/hot.svg' }]
        : []),
      ...(templateCategories ?? []),
    ]
  }, [templateCategories, hotTemplates])

  const router = useRouter()

  useEffect(() => {
    if (templateCategories?.length && !activeCategoryKey) {
      setActiveCategoryKey(templateCategories[0]?.category)
    }
  }, [templateCategories, activeCategoryKey])

  const activeCategory = useMemo(() => {
    return templateCategories?.find(({ category }: TemplateCategory) => category === activeCategoryKey) ?? null
  }, [templateCategories, activeCategoryKey])

  const subCategoryTags: string[] = useMemo(() => {
    const tags = activeCategory?.tags ?? []
    if (tags.length <= 2) {
      return tags
    }
    // pick random 2 tags
    const randomSortedTags = tags.sort(() => 0.5 - Math.random()).slice(0, 2)
    return randomSortedTags
  }, [activeCategory])

  const templates: Template[] = useMemo(() => {
    const tagsSet = new Set(subCategoryTags)
    if (activeCategoryKey === 'hot') {
      return allTemplates?.records?.filter((item: Template) => item.tags.some((tag) => tagsSet.has(tag))) ?? []
    }

    return allTemplates.records?.filter((item: Template) => item.category === activeCategoryKey) ?? []
  }, [activeCategoryKey, allTemplates, subCategoryTags])

  const handleGotoCategory = useCallback(
    (subCategory?: string) => {
      if (activeCategory) {
        const fragment = subCategory ? `#sub-${subCategory}` : ''
        router.push(`/templates?category=${activeCategory.category}${fragment}`)
      }
    },
    [activeCategory, router],
  )

  const { width: windowWidth } = useWindowSize() // watch window size

  const autoScrollCategoryContainer = useCallback(
    (key?: string) => {
      const container = categoryContainerRef.current
      if (!container) {
        return
      }

      const realKey = key ?? activeCategoryKey

      const activeItemLeft = (container.querySelector(`[data-category="${realKey}"]`) as HTMLDivElement)?.offsetLeft
      const viewPortWidth = container.clientWidth
      const scrollLeft = Math.min(
        Math.max(0, activeItemLeft - viewPortWidth / 2),
        container.scrollWidth - viewPortWidth,
      )

      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
    },
    [activeCategoryKey],
  )

  useEffect(() => {
    autoScrollCategoryContainer()
  }, [windowWidth, autoScrollCategoryContainer])

  const activeCategoryName = useMemo(() => {
    return activeCategoryKey === 'hot' ? 'Hot' : activeCategory?.label
  }, [activeCategory, activeCategoryKey])

  const handleGroupClick = useCallback(
    (key: string) => {
      setActiveCategoryKey(key)

      // scroll to the selected category
      autoScrollCategoryContainer(key)
    },
    [autoScrollCategoryContainer],
  )

  const [keyword, setKeyword] = useState('')

  const searchParams = useMemo(() => {
    return {
      query: keyword,
    }
  }, [keyword])
  const { data: searchResult, isValidating: searching } = useSearchTemplates(searchParams)

  const openTemplateDetail = useCallback(
    (templateId: string) => {
      router.push(`/template/${templateId}`)
    },
    [router],
  )

  const hasTemplates = templates?.length > 0

  const templateSubGroups: TemplateSubGroupProps[] = useMemo(() => {
    if (subCategoryTags.length === 0) {
      return [
        {
          name: '',
          label: activeCategoryName,
          templates,
        },
      ]
    }

    return subCategoryTags.map((tag) => {
      return {
        name: tag,
        label: snakeToSpace(tag),
        templates: templates.filter((template) => template.tags.includes(tag)),
      }
    })
  }, [subCategoryTags, templates, activeCategoryName])

  const renderTemplateSearchResult = useCallback(
    (template: SearchTemplateResultItem) => {
      const { name, introduction, cover_image, template_id } = template
      return (
        <div
          key={template_id}
          className='flex gap-2.5 px-4 py-2 border-b last-of-type:border-b-0 border-border cursor-pointer bg-surface hover:bg-surface-hover'
          onClick={() => openTemplateDetail(template_id)}
        >
          <div className='aspect-video overflow-hidden shrink-0 w-19 h-12'>
            <img src={cover_image} alt={name} className='aspect-video w-full h-full object-cover rounded-md' />
          </div>
          <div className='flex flex-col gap-1 flex-1'>
            <span className='text-heading-md font-bold tracking-32'>{name}</span>
            <span className='text-body-md tracking-15'>{introduction}</span>
          </div>
        </div>
      )
    },
    [openTemplateDetail],
  )

  const handleSubmitIdeasButtonClick = useCallback(() => {
    whisper('openFeedbackModal')
  }, [])

  return (
    <div ref={ref} className={cls('flex flex-col w-full z-0', className)}>
      <div className='text-2xl font-bold tracking-45 block mb-4'>
        {/* <span className='hidden md:block'>Templates powered by AI</span> */}
        <span>{'Templates (Preview)'}</span>
      </div>
      <div
        className='rounded-xl md:rounded-b-none px-4 pt-6 flex flex-col items-center gap-4 md:gap-6 text-text-on-color bg-gradient-to-r from-band-500 to-band-700'
        aria-label='search'
      >
        <div className='flex flex-col gap-2 w-full md:w-[600px]' aria-label=''>
          <span className='text-body-lg tracking-32'>Start creation from Templates</span>
          <SearchInput
            className='text-text w-full'
            placeholder='Search for Templates'
            defaultValue={keyword}
            dropdown={
              searchResult?.length ? (
                <div className='bg-surface rounded-md shadow-md w-full'>
                  {searching && (
                    <div className='flex w-full items-center justify-center py-6'>
                      <Loading className='h-6' />
                    </div>
                  )}
                  {searchResult?.map(renderTemplateSearchResult)}
                </div>
              ) : (
                <div className='flex flex-col w-full rounded-md bg-surface shadow-md gap-1.5'>
                  {searching ? (
                    <div className='w-full px-4 h-[68px] flex justify-center items-center'>
                      <Loading className='h-6' />
                    </div>
                  ) : (
                    <div className='flex flex-col w-full gap-1.5'>
                      <div className='w-full flex flex-col py-6 items-center justify-center gap-2' aria-label='empty'>
                        <IconEmpty className='size-12' />
                        <span className='text-heading-sm font-bold tracking-15 text-text'>No Results Found</span>
                      </div>
                      <div className='p-4 pt-0 w-full flex justify-center' aria-label='ideas'>
                        <div className='bg-surface-subdued rounded-md p-4 gap-4 w-full flex flex-col items-center'>
                          <div className='flex flex-col gap-1 max-w-[346px]' aria-label='introduction'>
                            <span className='text-heading-md font-bold tracking-32 w-full text-center'>
                              Tell us what you want
                            </span>
                            <span className='text-body-sm text-center'>
                              No templates match your search, but we'd love to hear your ideas Help us create templates
                              that suit your needs
                            </span>
                          </div>
                          <Button variant='primary' className='px-3 py-2' onClick={handleSubmitIdeasButtonClick}>
                            <div className='flex items-center gap-1'>
                              <IconUpload className='size-6 text-icon-on-color' />
                              <span className='text-body-md tracking-15 px-1'>Submit your idea</span>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='flex flex-col w-full' aria-label='hot templates'>
                    <div className='text-body-md font-medium text-text-subdued px-4'>Hot Templates</div>
                    {hotTemplates?.records?.map(renderTemplateSearchResult)}
                  </div>
                </div>
              )
            }
            onChange={setKeyword}
          />
        </div>
        <div
          ref={categoryContainerRef}
          className='flex items-center px-3 gap-10 md:gap-6 max-w-full overflow-x-auto overflow-y-hidden mx-auto no-scrollbar'
        >
          {displayTemplatesCategories?.map(({ category, label, icon }, index) => {
            const isActive = activeCategoryKey === category
            return (
              <div
                key={[category, index].join()}
                className='relative flex flex-col items-center gap-1 pb-6 md:pb-10 cursor-pointer w-10 md:w-22 shrink-0'
                data-category={category}
                onClick={() => {
                  handleGroupClick(category)
                }}
              >
                <div className='flex justify-center items-center size-10 md:size-12 bg-white rounded-full hover:opacity-80'>
                  <TemplateIcon
                    src={icon}
                    alt='template category logo'
                    className='size-10 md:size-12 border-0 border-none'
                    iconClassName='size-[27px] md:size-8'
                  />
                </div>
                <span className='text-body-sm md:text-body-md md:font-medium tracking-15 whitespace-nowrap'>
                  {label}
                </span>
                {/* selected bottom arrow */}
                {isActive && (
                  <div className='absolute bottom-0 md:bottom-4 inset-x-0 flex items-center justify-center'>
                    <IconPolygon className='size-6 translate-y-2 text-surface md:text-surface-subdued' />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div
        className='w-full md:flex md:justify-center rounded-xl bg-surface md:bg-surface-subdued flex flex-col gap-2 md:p-4 md:-translate-y-4 mt-4 md:mt-0'
        aria-label='list'
      >
        {hasTemplates ? (
          <div className='flex flex-col w-full gap-4'>
            {templateSubGroups.map((item) => (
              <TemplateSubGroup
                key={item.name}
                {...item}
                containerRef={ref}
                hasMore={activeCategoryKey !== 'hot'}
                onLoadMore={() => handleGotoCategory(item.name)}
              />
            ))}
          </div>
        ) : (
          <div className='relative h-20 flex flex-col items-center justify-center'>
            <span className='text-heading-md font-bold tracking-32 w-full'>{activeCategoryName} Templates</span>
            {!allTemplatesLoading && (
              <span className='text-text-subdued text-heading-xl leading-6 tracking-32 font-medium'>Coming soon</span>
            )}
          </div>
        )}
        <div className='w-full flex items-center justify-center gap-4'>
          <Button
            variant='outline'
            className='w-25 bg-surface hover:bg-surface hover:border-border-hover'
            onClick={refreshAllTemplates}
          >
            Refresh
          </Button>
          {activeCategoryKey !== 'hot' && (
            <Button variant='primary' className='w-25' onClick={() => handleGotoCategory()}>
              See more
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
